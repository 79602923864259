import React, { useState } from 'react';
import { useTheme } from '../../context/themeProvider';
import { useNews } from '../../context/newsProvide';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';

export function ReadingTime() {
  const { colors } = useTheme();
  const { readingTime, setReadingTime } = useNews();
  const [isOpen, setIsOpen] = useState(false);

  const readingTimeOptions = [
    { value: '5', label: '5 min read' },
    { value: '10', label: '10 min read' },
    { value: '15', label: '15 min read' },
    { value: '30', label: '30 min read' },
    { value: '60', label: '60 min read' },
  ];

  const handleReadingTimeChange = (value) => {
    setReadingTime(value ? parseInt(value, 10) : null);
    MixpanelEvent('news_reading_time_clicked', { reading_time: `${value} min read` });
    sendPostHogEvent('news_reading_time_clicked', { reading_time: `${value} min read` });
    setIsOpen(false); 
  };

  const handleClear = () => {
    setReadingTime(null); 
    MixpanelEvent('news_reading_time_cleared', { reading_time: 'cleared' });
    sendPostHogEvent('news_reading_time_cleared', { reading_time: 'cleared' });
    setIsOpen(false); 
  };

  return (
    <div className="relative inline-block w-40 h-10 z-10">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`cursor-pointer ${readingTime ? 'text-text' : 'text-disableTextColor'} py-1.5 px-2 rounded-lg bg-background border border-border`}
      >
        {readingTime ? `${readingTime} min read` : 'Reading Time'}
      </div>

      {readingTime && (
         <button
         onClick={handleClear}
         className="absolute top-1 right-2 border-none bg-transparent cursor-pointer text-2xl  text-text"
       >
         &times;
       </button>
   
      )}

      {isOpen && (
        <div className="absolute left-0 mt-2 w-full bg-cardBackground border border-border  rounded-lg shadow-lg z-20">
          {readingTimeOptions.map((option) => (
            <div
              key={option.value}
              onClick={() => handleReadingTimeChange(option.value)}
              className={`cursor-pointer py-2 px-4 ${readingTime === parseInt(option.value, 10) ? 'bg-cardBackground text-white' : 'text-text hover:bg-selectedOptionBackground'}`}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
