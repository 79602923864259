import React, { useState } from 'react';
import LatestNews from '../../components/News/latestNews';
import TodaysQuestions from '../../components/News/todaysQuestions';
import SavedTab from '../../components/News/saved';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useTheme } from '../../context/themeProvider';

export default function Breadcrumbs({ setActiveTab }) {
  const [activeTab, setActiveTabState] = useState('latest');
  const { colorScheme } = useTheme();

  const tabLabels = {
    latest: 'Latest News',
    'todays-questions': "Today's Questions",
    saved: 'Saved',
  };

  const handleTabChange = (value) => {
    setActiveTabState(value);
    setActiveTab(value);
    const tabLabel = tabLabels[value];
    MixpanelEvent('news_tab_changed', { tab_name: tabLabel });
    sendPostHogEvent('news_tab_changed', { tab_name: tabLabel });
  };

  const getTabStyle = (tab) => ({
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: activeTab === tab ? '1px solid blue' : '1px solid transparent',
    padding: '10px',
    cursor: 'pointer',
    fontWeight: 'normal',
    background: 'none',
    outline: 'none',
    color: activeTab === tab 
      ? (colorScheme === 'dark' ? 'white' : 'black') 
      : colorScheme === 'dark'
        ? '#8B8B8B'
        : '#747474',
  });

  return (
    <div className="absolute top-[60px] w-full z-[999] bg-background">
      <div className="flex justify-between items-center w-full">
        <div className="w-full">
          <div className="flex justify-evenly border-b border-[#2E2E2E]">
            {Object.entries(tabLabels).map(([value, label]) => (
              <button
                key={value}
                onClick={() => handleTabChange(value)}
                style={getTabStyle(value)}
                className="text-[15px] transition-all duration-300"
              >
                {label}
              </button>
            ))}
          </div>

          <div >
            {activeTab === 'latest' && <LatestNews />}
            {activeTab === 'todays-questions' && <TodaysQuestions />}
            {activeTab === 'saved' && <SavedTab />}
          </div>
        </div>
      </div>
    </div>
  );
}