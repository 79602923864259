import React, { useState, useEffect, useRef, useMemo } from 'react';
import { IconHeart, IconExclamationCircle, IconEyeOff, IconEye, IconCheck, IconX } from '@tabler/icons-react';
import {Text, Badge,Stack, Radio, Textarea } from '@mantine/core';
import { useTheme } from '../../context/themeProvider';
import Lottie from 'react-lottie';
import * as successAnimation from '../../assets/json/report_success.json';
import {  ArrowLeftDarkIcon, ArrowLeftFilled, ArrowRightDarkIcon, ArrowRightFilled, NoDataImage } from '../../assets/images';
import { userStore } from '../../stores/userStore';
import { callGETAPI } from '../../utils/NetworkUtil';
import { getNewsPaperParamForApi } from '../../utils/NewsFeedUtils';
import dayjs from 'dayjs';
import { MixpanelEvent, sendPostHogEvent } from '../../utils/mixpanelUtil';
import { useNavigate } from 'react-router-dom';

export default function TodaysQuestions() {
  const { colorScheme } = useTheme();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionStates, setQuestionStates] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = window.innerWidth <= 2500;
  const [details, setDetails] = useState('');
  const [resportData, setResportData] = useState('');
  const [isReportSubmited, setReportSubmit] = useState(false);
  const [selectedQuestion, setSelectedQuestionId] = useState('');
  const userData = userStore((state) => state.userData);
  const userId = userData.user;
  const containerRef = useRef(null);
  const questionNavRef = useRef(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [selectedNews, setSelectedNews] = useState('THE_HINDU');
  const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [mcqQuestions, setMcqQuestions] = useState([]);
  const [mainsQuestions, setMainsQuestions] = useState([]);
  const [prelimsQuestions, setPrelimsQuestions] = useState([]);
  const [activeTab, setActiveTab] = useState('mcqs');
  const [showAnswer, setShowAnswer] = useState(false);
  const navigate = useNavigate();
  const memoizedQuestions = useMemo(() => {
    switch (activeTab) {
      case 'mcqs':
        return mcqQuestions;
      case 'mains':
        return mainsQuestions;
      case 'prelims':
        return prelimsQuestions;
      default:
        return [];
    }
  }, [activeTab, mcqQuestions, mainsQuestions, prelimsQuestions]);

  // const [props, set] = useSpring(() => ({
  //   x: 0,
  //   config: { tension: 300, friction: 30 },
  // }));

  useEffect(() => {
    if (selectedDate && userData) {
      let apiUrl = `${process.env.REACT_APP_BASE_URL}/api/collatedmcqs/?news_paper=${getNewsPaperParamForApi(
        selectedNews
      )}&date=${selectedDate}&user=${userData.user}`;

      callGETAPI(
        apiUrl,
        (resp) => {
          setMcqQuestions(resp.data.mcqs || []);
          setMainsQuestions(resp.data.pyqs.mains_questions || []);
          setPrelimsQuestions(resp.data.pyqs.prelims_questions || []);
        },
        (err) => {
          console.error('API error:', err);
          setMcqQuestions([]);
          setMainsQuestions([]);
          setPrelimsQuestions([]);
        }
      );
    }
  }, [selectedNews, selectedDate, userData]);

  useEffect(() => {
    setQuestionStates(memoizedQuestions.map(() => ({ isSubmitted: false, selectedOption: null, isCorrect: false, answerIndex: null })));
    setCurrentQuestionIndex(0);
  }, [memoizedQuestions]);

  useEffect(() => {
    if (questionNavRef.current) {
      const activeQuestion = questionNavRef.current.querySelector(`[data-index="${currentQuestionIndex}"]`);
      if (activeQuestion) {
        activeQuestion.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      }
    }
  }, [currentQuestionIndex]);

  const currentQuestion = memoizedQuestions[currentQuestionIndex];

  const dictMyCategoryName = (category) => {
    const categories = {
      economy: 'economy',
      history: 'history',
      geography: 'geography',
      polity: 'polity',
      science: 'science',
      environment: 'environment',
      csat: 'csat',
    };

    const categoryLower = category?.toLowerCase();

    return categories[categoryLower] || 'current_affairs';
  };

  const handleOptionChange = (index) => {
    if (!questionStates[currentQuestionIndex].isSubmitted) {
      setQuestionStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[currentQuestionIndex].selectedOption = currentQuestion.options[index];
        newStates[currentQuestionIndex].answerIndex = index;
        return newStates;
      });
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const discussWithAi = (item, checkedStat) => {
    console.log(item, checkedStat);
    const optionKey = ['a', 'b', 'c', 'd'];
    let tutorConnectParam = {
      assoc_news: currentQuestion.news_summary_id,
      flowType: 'postGame',
      source: 'news_feed',
      id: null,
      chatType: 'ai_tutor',
      node_id: null,
      chatgraph_id: null,
      messages: [
        {
          role: 'user',
          convo_id: '',
          type: 'Question',
          content: item.question,
        },
        {
          role: 'user',
          convo_id: '',
          type: 'My Answer',
          content: item.options[parseInt(checkedStat)],
        },
      ],
      preSelectQuestion: {
        answer: null,
        choices: [
          {
            a: item.options[0],
          },
          {
            b: item.options[1],
          },
          {
            c: item.options[2],
          },
          {
            d: item.options[3],
          },
        ],
        category: dictMyCategoryName(item.category),
        question: item.question,
        question_id: item.id,
        user_answer: item.options[checkedStat],
        user_option: optionKey[checkedStat],
        correct_answer: optionKey[item.answer],
        options: item.options,
      },
      category: dictMyCategoryName(item.category),
      preSelectAnswer: item.options[parseInt(checkedStat)],
    };
    return tutorConnectParam;
  };

  const handleSubmit = () => {
    const correctAnswerIndex = currentQuestion.answer;
    const isAnswerCorrect = questionStates[currentQuestionIndex].answerIndex === correctAnswerIndex;
    setQuestionStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[currentQuestionIndex].isSubmitted = true;
      newStates[currentQuestionIndex].isCorrect = isAnswerCorrect;
      return newStates;
    });
  };

  const handleNextQuestion = () => {
    // set({ x: -window.innerWidth });
    setShowAnswer(false);
    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex < memoizedQuestions.length - 1 ? prevIndex + 1 : prevIndex));
      // set({ x: 0, immediate: true });
    }, 300);
  };

  const handlePreviousQuestion = () => {
    // set({ x: window.innerWidth });
    setTimeout(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      // set({ x: 0, immediate: true });
    }, 300);
  };

  const handleTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
    // set({ x: touchStart - e.targetTouches[0].clientX });
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;
    if (isLeftSwipe) {
      handleNextQuestion();
    } else if (isRightSwipe) {
      handlePreviousQuestion();
    } else {
      // set({ x: 0 });
    }
  };

  const handleDiscussWithAi = () => {
    const aiTutorParams = discussWithAi(currentQuestion, questionStates[currentQuestionIndex].answerIndex);
    navigate(`/chat/mcq/`, { state: { aiTutorParams } });
    MixpanelEvent('discuss_with_ai', { source: 'news_mcq' });
    sendPostHogEvent('discuss_with_ai', { source: 'news_mcq' });
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setDetails('');
  };

  const handleReport = (questionId) => {
    setIsOpen(true);
    setSelectedQuestionId(questionId);
    setReportSubmit(false);
    setResportData('');
  };

  const handleResportSubmit = async () => {
    setReportSubmit(true);
    let postPayload = {
      flow: 'newspyq',
      question_id: selectedQuestion,
      user: userId,
      option: resportData,
      reason: '',
    };
  
    if (resportData === 'Other') {
      postPayload.reason = details;
    }
  
    MixpanelEvent('submit_feedback', {
      category: currentQuestion?.category || '',
      source: 'news_today_questions',
      selection: resportData,
      question_id: selectedQuestion
    });
  
    sendPostHogEvent('submit_feedback', {
      category: currentQuestion?.category || '',
      source: 'news_today_questions',
      selection: resportData,
      question_id: selectedQuestion
    });
  
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/track/question-reporting/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postPayload),
      });
      const json = await res.json();
  
      if (res.status === 400) {
        MixpanelEvent('report_submitted', { 
          content_id: selectedQuestion, 
          error: json,
          source: 'news_today_questions'
        });
        sendPostHogEvent('report_submitted', { 
          content_id: selectedQuestion, 
          error: json,
          source: 'news_today_questions'
        });
      } else {
        MixpanelEvent('report_submitted', { 
          content_id: selectedQuestion,
          source: 'news_today_questions'
        });
        sendPostHogEvent('report_submitted', { 
          content_id: selectedQuestion,
          source: 'news_today_questions'
        });
      }
  
      setTimeout(() => {
        setIsOpen(false);
      }, 3000);
    } catch (error) {
      console.error('Error submitting report:', error);
      MixpanelEvent('report_submission_error', {
        error: error.message,
        source: 'news_today_questions'
      });
      sendPostHogEvent('report_submission_error', {
        error: error.message,
        source: 'news_today_questions'
      });
    }
  };

  const toggleShowAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  const formatQuestionText = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };
  const ReportModalContent = ({
    isOpen,
    isMobile,
    closeModal,
    isReportSubmited,
    resportData,
    setResportData,
    details,
    onChange,
    handleResportSubmit,
    colors,
  }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-end z-50">
        <div 
          className={`p-5 rounded-t-xl w-full text-center shadow-lg text-text bg-cardBackground`}
          style={{ 
            maxWidth: isMobile ? '28rem' : '22vw',
          }}
        >
          <div className="flex items-center justify-center w-full">
            <span 
              className="h-1.5 w-24 block bg-gray-200 rounded-full cursor-pointer"
              onClick={closeModal}
            ></span>
          </div>
          <button
            onClick={closeModal}
            className="relative -right-2 border-none bg-none cursor-pointer text-3xl  text-text w-full flex justify-end"
          >
            &times;
          </button>
  
          {!isReportSubmited ? (
            <>
            <div className="text-left m-4 text-xl">Report Question</div>
            <Stack className="space-y-4">
              <Radio.Group value={resportData} onChange={setResportData} size="1em">
                {[
                  'Wrong category',
                  'Answer is incorrect',
                  'Incomplete question',
                  'Mistake in question',
                  'Mistake in options',
                  'Poorly formatted question',
                  'Poorly formatted options',
                  'Other',
                ].map((item, index) => (
                  <Radio key={index} value={item} label={item} className="p-4" />
                ))}
              </Radio.Group>

              {resportData === 'Other' && (
                <>
                  <Text className="text-left">Other reason*</Text>
                  <Textarea
                    placeholder="Provide details about your issue here..."
                    value={details}
                    rows={4}
                    radius="sm"
                    className="mt-2.5 text-sm"
                    onChange={onChange}
                  />
                </>
              )}
            </Stack>

            <div className="mt-6">
              <button
                onClick={handleResportSubmit}
                disabled={!resportData}
                className={`w-full rounded-full p-4 font-medium font-poppins 
                  ${resportData !== "" ? colorScheme === "dark" ? "bg-white" : "bg-[#070519]" : colorScheme === "light" ? "bg-[#F3F3F3]" : "bg-[#464646]"}
                  ${resportData !== "" ? colorScheme === "dark" ? "text-[#070519]" : "text-white" : colorScheme === "light" ? "text-[#B9B9B9]" : "text-[#5D5D5D]"}
                  `}
              >
                Submit Feedback
              </button>
            </div>
          </>
          ) : (
            <>
              <div className="text-2xl my-4">
                Report Submitted
              </div>
              <div className="text-base my-4">
                Thank you! Your report has been submitted.
              </div>
              <Lottie options={defaultOptions} height={200} width={200} />
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={`font-poppins text-gray-800 max-w-600 mx-auto min-h-dvh h-full flex flex-col  relative pb-20`}>
      <div className={`p-4  shadow-md`}>
        {memoizedQuestions.length > 0 && (
          <div className="flex justify-between items-center">
          <img
            src={colorScheme === 'dark' ? ArrowLeftDarkIcon : ArrowLeftFilled}
            className={`cursor-pointer bg-cardBackground rounded-lg border border-[#D1D1D1] p-1.5 ${currentQuestionIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handlePreviousQuestion}
            alt="Arrow Left"
          />
            <div className="flex items-center gap-2 overflow-x-auto scrollbar-hidden flex-1 mx-2" ref={questionNavRef}>
              {memoizedQuestions.map((_, index) => (
               <div
                  key={index}
                  data-index={index}
                  className={`min-w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer mx-1.5 flex-shrink-0 ${
                    index === currentQuestionIndex
                      ? `${colorScheme === 'dark' ? 'bg-[#EBF3FF] text-black' : 'bg-[rgb(219,219,219)] text-[#6374FA]'}`
                      : 'bg-transparent text-text'
                  }`}
                  onClick={() => setCurrentQuestionIndex(index)}
                >
                  Q{index + 1}
                </div>
              ))}
            </div>
            <img
            src={colorScheme === 'dark' ? ArrowRightDarkIcon : ArrowRightFilled}
            className={`cursor-pointer bg-cardBackground rounded-lg border border-[#D1D1D1] p-1.5  ${
              currentQuestionIndex === memoizedQuestions.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleNextQuestion}
            alt="Arrow Right"
          />
          </div>
        )}
      </div>

      {memoizedQuestions.length === 0 ? (
        <div className="text-center p-5 h-60 flex flex-col justify-center items-center text-gray-600">
          <img src={NoDataImage} alt="no data" className="mb-5" />
          <Text className="font-semibold mb-2">No Related Questions Found</Text>
          <Text className="text-gray-500">We were unable to generate any questions for this category. Please try another category or date.</Text>
        </div>
      ) : (
        <div 
          className={`relative border bg-cardBackground rounded-xl border-border m-4 h-[calc(100dvh-180px)] overflow-hidden`}
          ref={containerRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="w-full h-full">
            <div 
              className={`p-4 h-[82%] overflow-y-auto`}
            >
              <div className="flex justify-between items-center mb-3">
                <Badge color="gray">{activeTab.toUpperCase()}</Badge>
                <IconHeart className="text-gray-500 cursor-pointer" />
              </div>

              <div className="text-base text-text font-medium mb-5">
                {formatQuestionText(currentQuestion.question)}
              </div>

              {(activeTab === 'mcqs' || activeTab === 'prelims') && (
                <div className="flex flex-col text-text gap-3">
                  {currentQuestion.options.map((option, index) => {
                    const isSubmitted = questionStates[currentQuestionIndex]?.isSubmitted;
                    const isCorrectAnswer = index === currentQuestion.answer;
                    const isSelectedAnswer = index === questionStates[currentQuestionIndex]?.answerIndex;

                    return (
                      <div
                      key={index}
                      onClick={() => handleOptionChange(index)}
                      className={`
                        rounded-lg p-4 cursor-pointer border flex items-center gap-1.5 
                        ${isSubmitted
                          ? isCorrectAnswer
                            ? 'bg-[#2F6545] border-[#2F6545]'
                            : isSelectedAnswer
                              ? 'bg-[#9F4749] border-[#9F4749]'
                              : 'bg-cardBackground border-border'
                          : isSelectedAnswer
                            ? 'bg-selectedOptionBackground border-selectedOptionBackground'
                            : 'bg-cardBackground border-border'
                        }
                      `}
                    >
                      <div className="flex items-center">
                        <div
                          className={`w-6 h-6 rounded-full flex justify-center items-center mr-2.5 transition-all duration-300 ${
                            questionStates[currentQuestionIndex]?.isSubmitted
                              ? index === currentQuestion.answer
                                ? 'bg-[#34d399]'
                                : index === questionStates[currentQuestionIndex].answerIndex
                                ? 'bg-[#ff9999]'
                                : 'bg-transparent'
                              : 'bg-transparent'
                          } ${
                            questionStates[currentQuestionIndex]?.isSubmitted &&
                            (index === currentQuestion.answer || index === questionStates[currentQuestionIndex].answerIndex)
                              ? 'border-none'
                              : 'border-2 border-border'
                          }`}
                        >
                          {questionStates[currentQuestionIndex]?.answerIndex === index && !questionStates[currentQuestionIndex]?.isSubmitted && (
                            <div className="w-[14.5px] h-[14.5px] bg-[#6374FA] rounded-full"></div>
                          )}
                          {questionStates[currentQuestionIndex]?.isSubmitted &&
                            (index === currentQuestion.answer ? (
                              <IconCheck className="w-3.5 h-3.5 text-white" />
                            ) : index === questionStates[currentQuestionIndex].answerIndex ? (
                              <IconX className="w-3.5 h-3.5 text-white" />
                            ) : null)}
                        </div>
                        <div className="mr-[8px] text-[#6374FA]">{String.fromCharCode(65 + index)}.</div>
                        <div className="text-[15px] font-medium flex-1 text-text">{option}</div>
                      </div>
                    </div>
                    );
                  })}
                </div>
              )}

              {activeTab === 'mains' && showAnswer && (
                <div className="mt-5 p-4 bg-gray-100 rounded-lg">
                  <Text className="text-sm leading-relaxed">{formatQuestionText(currentQuestion.solution)}</Text>
                </div>
              )}
            </div>
            
            <div className="flex justify-between p-4 shadow-[0px_-4px_24px_0px_rgba(0,0,0,0.12)] w-full bg-cardBackground border-t border-border absolute bottom-0 left-0 right-0 z-50 box-border mb-4">
            <button
              className="text-[#D95353] flex items-center justify-center gap-1 py-2 px-4 rounded-full font-medium focus:outline-none"
              onClick={() => handleReport(currentQuestion.id)}
              >
              <IconExclamationCircle size={16} />
              Report
            </button>         
              {activeTab === 'mains' ? (
                <button 
                  onClick={toggleShowAnswer}
                  className="flex items-center px-4 py-2 bg-gray-800 text-white rounded-full"
                >
                  {showAnswer ? <IconEyeOff className="mr-2" /> : <IconEye className="mr-2" />}
                  {showAnswer ? 'Hide Answer' : 'Show Answer'}
                </button>
              ) : (activeTab === 'mcqs' || activeTab === 'prelims') && !questionStates[currentQuestionIndex]?.isSubmitted ? (
                <button
                  onClick={handleSubmit}
                  disabled={questionStates[currentQuestionIndex]?.answerIndex === null}
                  className={`${
                    questionStates[currentQuestionIndex]?.selectedOption
                      ? (colorScheme === 'dark' ? 'bg-white text-black' : 'bg-black text-white')
                      : 'bg-transparent text-[#B9B9B9] cursor-not-allowed border border-[#D1D1D1] hover:bg-transparent transition-all duration-300 opacity-50'
                  } rounded-full py-2 px-6 h-[2.5rem] text-sm font-medium`}
                >
                  Submit
                </button>
              ) : (
                <button 
                  onClick={handleDiscussWithAi}
                  className="px-4 py-2 border border-text text-text  rounded-full"
                >
                  Discuss with AI
                </button>
              )}
            </div>
          </div>
        </div>
      )}
            
      <ReportModalContent
        isMobile={isMobile}
        isOpen={isOpen}
        closeModal={handleCloseModal}
        resportData={resportData}
        details={details}
        handleResportSubmit={handleResportSubmit}
        setResportData={setResportData}
        isReportSubmited={isReportSubmited}
        onChange={(e) => setDetails(e.currentTarget.value)}
      />
    </div>
  );
}
